import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      // backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  sortLabel: {
    paddingLeft: '14px',
    '& svg': {
      width: '18px',
      marginRight: '-4px',
    },
  },
}));

export default function UserAdminTable(records, headCell, filterFn) {
  const classes = useStyles();

  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const getCellWidth = (index) => {
    if (headCell[index].width) {
      return `${headCell[index].width}%`;
    }

    const totalColumns = headCell.length;
    const columnsToExclude = headCell.filter((cell) => cell.width).length;
    const takenWidth = headCell.reduce((acc, cell) => (cell.width ? acc + cell.width : acc), 0);
    const remainingWidth = 100 - takenWidth;
    const cellWidth = remainingWidth / (totalColumns - columnsToExclude);

    return `${cellWidth}%`;
  };

  const TablContainer = (props) => (
    // <Table className={classes.table} stickyHeader aria-label="sticky table">
    //     {props.children}
    // </Table>
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow loader="bubble-spin" color="#21309D" size={9}>
          {headCell.map((cell, index) => (
            <TableCell
              key={cell.id}
              align="center"
              sortDirection={orderBy === cell.id ? order : false}
              width={getCellWidth(index)}
            >
              {cell.disableSorting ? (
                cell.label
              ) : (
                <TableSortLabel
                  active={orderBy === cell.id}
                  className={classes.sortLabel}
                  // style={[4, 5].includes(index) ? { padding: '0 40%' } : {}}
                  direction={orderBy === cell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(cell.id);
                  }}
                >
                  {cell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) return newOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, newOrderBy) {
    if (b[newOrderBy] < a[newOrderBy]) {
      return -1;
    }
    if (b[newOrderBy] > a[newOrderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(newOrder, newOrderBy) {
    return newOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, newOrderBy)
      : (a, b) => -descendingComparator(a, b, newOrderBy);
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(filterFn.fn(records), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    );
  };

  return {
    TablContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  };
}
