import { Button } from '@mui/material';

const PrimaryButton = (props) => {
  const { text, onClick } = props;

  return (
    <Button
      onClick={onClick}
      className="btn btn-dark btn-primary_submit submitButton shadow-none"
      color="primary"
      autoFocus
    >
      <span className="MuiButton-label">{text}</span>
    </Button>
  );
};

export default PrimaryButton;
