import { Button } from '@mui/material';

const SecondaryButton = (props) => {
  const { text, onClick } = props;

  return (
    <Button
      className="btn btn-dark btn-primary_submit cancelButton shadow-none"
      style={{
        lineHeight: 1.5,
        color: '#21309d',
        fontWeight: '600',
        outlineStyle: 'none !important',
        backgroundColor: '#ffffff !important',
        fontFamily: 'Quicksand',
        borderRadius: '18px',
        borderColor: '#21309d',
        border: '2px solid #21309d',
      }}
      onClick={onClick}
      color="secondary"
    >
      <span className="MuiButton-label">{text}</span>
    </Button>
  );
};

export default SecondaryButton;
