import { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import {
  Typography,
  Box,
  TextField,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Checkbox,
} from '@mui/material';

import Loader from './BackdropLoader';
import CompanyPositionChanges from './CompanyPositionChanges';
import AxiosInstance from '../../api/axios';
import AlertModel from './AlertBox';
import QueuedReportsStep from './CompanyUploadReport/QueuedReportsStep';
import ScoreCardStep from './CompanyUploadReport/ScoreCardStep';
import RecalculateQueuedReportsStep from './CompanyUploadReport/RecalculateQueuedReportsStep';
import SecondaryButton from './CompanyUploadReport/SecondaryButton';
import PrimaryButton from './CompanyUploadReport/PrimaryButton';
import StepTitle from './CompanyUploadReport/StepTitle';
import CloseButton from './CompanyUploadReport/CloseButton';
import GridColumn from './CompanyUploadReport/GridColumn';
import EmailFields from './emailData';
import { TOAST_OPTIONS } from '../../utils/toast';
import { REPORT_NOTIFICATION_TYPES, reportStatus } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const FormStep = {
  QUEUED_REPORTS: 'queued_reports',
  SCORE_CARD: 'score_card',
  UPLOAD_REPORT: 'upload_report',
  RECALCULATED_REPORTS: 'recalculated_reports',
};

const CompanyUploadReport = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = 'lg';
  const {
    openUploadReportModel,
    setUploadReportModel,
    uploadReport,
    loadCompany,
    urlReq,
    upoadReportTitleName,
    setUploadReportTitleName,
    uploadReportCompanyID,
    setUploadReportCompanyID,
    open,
    setOpen,
    subject,
    setSubject,
    latestScore,
    setLatestScore,
    mailBody,
    setMailBody,
    selectedSectors,
    setSelectedSectors,
    file,
    setFile,
    fileName,
    setFileName,
    // selectedDate,
    // setSelectedDate,
    selectedCountry,
    setSelectedCountry,
    // setgetTimeZoneValue,
    // getTimeZoneValue,
  } = props;
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [time, setTime] = useState(null);

  const [sectorsList, setSectorList] = useState([{}]);
  const [sectorName, setSectorName] = useState('');
  const [countryList, setCountryList] = useState([{}]);
  const [notificationType, setNotificationType] = useState(REPORT_NOTIFICATION_TYPES.ALL_USERS);
  const [score, setScore] = useState(latestScore);
  const [scoreCardMap, setScoreCardMap] = useState({});
  const [scoreError, setScoreError] = useState('');
  const [showPositionChanges, setShowPositionChanges] = useState(false);
  const [ignoreQueuedReports, setIgnoreQueuedReports] = useState(false);
  const [positionChanges, setPositionChanges] = useState({});
  const [queuedReports, setQueuedReports] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [removeSpreadsheetFromNotification, setRemoveSpreadsheetFromNotification] = useState(false);
  const [steps, setSteps] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [getTimeZoneValue, setgetTimeZoneValue] = useState(null);

  const newScoreInputRef = useRef(null);

  let dateFormat = '';
  if (selectedDate !== null) {
    dateFormat = moment(selectedDate).format('YYYY-MM-DD');
  }

  /**
   * Handle modal close
   */

  const handleClose = () => {
    setTime(null);
    setUploadReportModel(false);
  };

  /**
   * Handle report score change
   */

  const handleScoreChange = (event) => {
    const value = event.target.value.trim();
    const formattedValue = value ? parseInt(value, 10) : null;

    setScore(formattedValue);
    setScoreError('');

    if (formattedValue === null) {
      setScoreError('Score is required');
    } else if (formattedValue.toString() !== value) {
      setScoreError('Score must be a number');
    }
  };

  /**
   * Handle report time change
   */

  const handleTimeChange = (event) => {
    const str = String(event.target.value);
    setTime(str);
  };

  /**
   * Show error notification based on the response
   */

  const errorNotification = (message) => {
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code =
      message && message.response && message.response.status && message.response.status ? message.response.status : 500;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  /**
   * Show notification based on HTTP response status
   */

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  /**
   * Handle file upload
   */

  const uploadFile = (e) => {
    const fileValue = e.target.files;
    const render = new FileReader();
    render.readAsDataURL(fileValue[0]);
    const newFileName = fileValue[0].name;
    setFileName(newFileName);
    render.onload = (event) => {
      const fileData = event.target.result;
      const finalFile = fileData.split(/[,]/);
      setFile(finalFile[1]);
    };
  };

  /**
   * Validate specified date
   */

  const validateDate = (date) => {
    setSelectedDate(date);

    const body = {
      id: uploadReport.id,
      date_change: date,
      reportId: null,
    };

    AxiosInstance.post('company/checkReportDate', body)
      .then((response) => {
        const checkDateRes = response.data.date;
        if (checkDateRes === false) {
          setOpenAlertBox(true);
        } else {
          console.log('==> no issue');
        }
      })
      .catch((error) => {
        console.log('==>Errr sector', error);
      });
  };

  /**
   * Create report and close modal
   */

  const createReport = async () => {
    if (scoreError) {
      return null;
    }

    setOpen(true);

    const getSectorResult = _(sectorsList).keyBy('name').at(selectedSectors).value();
    const getFileteredSectorId = _.map(getSectorResult, 'id');

    const getCountryResult = _(countryList).keyBy('name').at(selectedCountry).value();
    const getFileteredCountryId = _.map(getCountryResult, 'id');

    const getOptionalFields = () => {
      switch (notificationType) {
        case REPORT_NOTIFICATION_TYPES.ALL_USERS:
          return {
            email_subject: subject,
            email_body: mailBody,
          };
        case REPORT_NOTIFICATION_TYPES.SECTORS_AND_COUNTRIES:
          return {
            email_subject: subject,
            email_body: mailBody,
            sectorIds: getFileteredSectorId,
            countryIds: getFileteredCountryId,
          };
        case REPORT_NOTIFICATION_TYPES.DISABLE:
        default:
          return {
            email_subject: '',
            email_body: '',
          };
      }
    };

    if (uploadReport == null) {
      setTime(null);
      toast.error('Something Went wrong', TOAST_OPTIONS);
      return null;
    }

    const { sector_id: sectorId } = uploadReport;
    const timeValue = time !== null ? time : getTimeZoneValue;
    const optionalFields = getOptionalFields();
    const body = {
      companyId: uploadReport.id,
      sectorId,
      score: parseInt(score, 10),
      notify_by_email: notificationType,
      date_change: dateFormat,
      fileType: 'application/pdf',
      fileName,
      file,
      ric_code: uploadReport.ric_code,
      time: `${timeValue}:00`,
      position_changes: positionChanges,
      ignore_queued_reports: ignoreQueuedReports,
      remove_scorecard_from_notification: removeSpreadsheetFromNotification,
      ...scoreCardMap,
      ...optionalFields,
    };
    console.log('createReport', body);

    try {
      const response = await AxiosInstance.post(urlReq, body);
      const { code } = response.data;
      const { message } = response.data;
      notificationMessage(message, code);
      setUploadReportModel(false);
      setUploadReportTitleName('');
      setUploadReportCompanyID(0);
      loadCompany();
      setOpen(false);
      setMailBody('');
      setSubject('');
      setLatestScore(1);
      setTime(null);
      setScore(1);
      setSelectedCountry([]);
      setSelectedSectors([]);
      setNotificationType(REPORT_NOTIFICATION_TYPES.ALL_USERS);

      return response.data.data || null;
    } catch (error) {
      errorNotification(error);
      // setUploadReportModel(false);
      setOpen(false);
      setCurrentStep(FormStep.UPLOAD_REPORT);
    }

    return null;
  };

  /**
   * Recalculate position schanges in queued reports
   */

  const handleRecalculateReports = async (reports) => {
    await AxiosInstance.post('admin/reports/bulkRecalculate', {
      dry_run: false,
      reports: reports.map(({ id }) => ({ id })),
      updated_company: {
        id: uploadReportCompanyID,
        score,
      },
    });
  };

  /**
   * Handle havigation to the previous step
   */

  const handlePrev = () => {
    switch (currentStep) {
      case FormStep.QUEUED_REPORTS:
        handleClose();
        break;
      case FormStep.SCORE_CARD:
        if (steps.includes(FormStep.QUEUED_REPORTS)) {
          setCurrentStep(FormStep.QUEUED_REPORTS);
        } else {
          handleClose();
        }
        break;
      case FormStep.UPLOAD_REPORT:
        setCurrentStep(FormStep.SCORE_CARD);
        break;
      case FormStep.RECALCULATED_REPORTS:
        setCurrentStep(FormStep.UPLOAD_REPORT);
        break;
      default:
    }
  };

  /**
   * Handle havigation to the next step
   */

  const handleNext = async () => {
    const stepIndex = steps.indexOf(FormStep.UPLOAD_REPORT);

    switch (currentStep) {
      case FormStep.QUEUED_REPORTS:
        setCurrentStep(FormStep.SCORE_CARD);
        break;
      case FormStep.SCORE_CARD:
        setCurrentStep(FormStep.UPLOAD_REPORT);
        break;
      case FormStep.UPLOAD_REPORT:
        if (stepIndex === steps.length - 1) {
          await createReport();
        } else {
          setCurrentStep(FormStep.RECALCULATED_REPORTS);
        }
        break;
      case FormStep.RECALCULATED_REPORTS:
        // eslint-disable-next-line no-case-declarations
        const newReport = await createReport();
        await handleRecalculateReports(queuedReports.filter((report) => report.id !== newReport?.id));
        break;
      default:
    }
  };

  /**
   * Check if user can navigate back
   */

  const canNavigateBack = () => {
    return steps.indexOf(currentStep) > 0;
  };

  /**
   * Generate dynamic step title
   */

  const generateStepTitle = (title, stepId, stepsIds) => {
    if (stepsIds.length === 1) {
      return title;
    }

    const index = stepsIds.indexOf(stepId);
    return `${index >= 0 ? `Step ${index + 1} of ${stepsIds.length}: ` : ''}${title}`;
  };

  /**
   * Perform initial data load
   */

  useEffect(() => {
    const loadQueuedReports = async () => {
      try {
        const { data } = await AxiosInstance.get(`admin/reports?status=${reportStatus.SCHEDULED}`);
        setQueuedReports(data.data);
        setCurrentStep(data.data.length > 0 ? FormStep.QUEUED_REPORTS : FormStep.SCORE_CARD);
      } catch (error) {
        console.log('==>Errr sector', error);
      }
    };

    const loadSectors = async () => {
      try {
        const { data } = await AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC');
        setSectorList(data.data);
      } catch (error) {
        console.log('==>Errr sector', error);
      }
    };

    const loadCountries = async () => {
      try {
        const { data } = await AxiosInstance.get('user/country');
        setCountryList(data.data);
      } catch (error) {
        console.log('==>Errr sector', error);
      }
    };

    loadQueuedReports();
    loadSectors();
    loadCountries();
  }, []);

  /**
   * Create steps list based on queued reports and the ignoreQueuedReports flag
   */

  useEffect(() => {
    const newSteps = [];

    if (queuedReports?.length > 0) {
      newSteps.push(FormStep.QUEUED_REPORTS);
    }

    newSteps.push(FormStep.SCORE_CARD);
    newSteps.push(FormStep.UPLOAD_REPORT);

    if (queuedReports?.length > 0 && ignoreQueuedReports) {
      newSteps.push(FormStep.RECALCULATED_REPORTS);
    }

    setSteps(newSteps);
  }, [queuedReports, ignoreQueuedReports]);

  /**
   * Refresh position changes when score, ignoreQueuedReports or company ID changes
   */

  useEffect(() => {
    const refreshPositionChanges = async () => {
      if (!score) {
        return;
      }
      try {
        const params = new URLSearchParams({
          score,
          ignore_queued_reports: ignoreQueuedReports,
        });
        const { data } = await AxiosInstance.get(
          `company/${uploadReportCompanyID}/calculatePositions?${params.toString()}`,
        );
        setPositionChanges(data.data);
      } catch (error) {
        console.log('==>Errr sector', error);
      }
    };
    refreshPositionChanges();
  }, [score, uploadReportCompanyID, ignoreQueuedReports]);

  /**
   * Refresh step data on current step change
   */

  useEffect(() => {
    if (currentStep !== FormStep.UPLOAD_REPORT) {
      return;
    }

    if (!selectedDate) {
      setSelectedDate(String(moment.tz('Etc/Universal').format('YYYY-MM-DD')));
    }

    if (!getTimeZoneValue) {
      setgetTimeZoneValue(String(moment.tz('Etc/Universal').format('HH:mm')));
    }
  }, [currentStep, selectedDate, setSelectedDate, getTimeZoneValue, setgetTimeZoneValue]);

  return (
    <div>
      <Loader setOpen={setOpen} open={open} />
      <AlertModel
        openAlertBox={openAlertBox}
        setOpenAlertBox={setOpenAlertBox}
        setSelectedDate={setSelectedDate}
        setUploadReportModel={setUploadReportModel}
      />

      {openUploadReportModel && steps.includes(FormStep.QUEUED_REPORTS) && currentStep === FormStep.QUEUED_REPORTS && (
        <QueuedReportsStep
          title={generateStepTitle('Queued Reports', FormStep.QUEUED_REPORTS, steps)}
          reports={queuedReports}
          handleClose={handleClose}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          handlePrev={handlePrev}
          handleNext={handleNext}
        />
      )}

      {openUploadReportModel && steps.includes(FormStep.SCORE_CARD) && currentStep === FormStep.SCORE_CARD && (
        <ScoreCardStep
          title={generateStepTitle('Score Card', FormStep.SCORE_CARD, steps)}
          companyId={uploadReportCompanyID}
          handleClose={handleClose}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          canNavigateBack={canNavigateBack}
          handlePrev={handlePrev}
          handleNext={handleNext}
          score={score}
          setScore={setScore}
          scoreCardMap={scoreCardMap}
          setScoreCardMap={setScoreCardMap}
          positionChanges={positionChanges}
          setPositionChanges={setPositionChanges}
          uploadReportCompanyID={uploadReportCompanyID}
          removeSpreadsheetFromNotification={removeSpreadsheetFromNotification}
          setRemoveSpreadsheetFromNotification={setRemoveSpreadsheetFromNotification}
          showPositionChanges={showPositionChanges}
          setShowPositionChanges={setShowPositionChanges}
        />
      )}

      <Dialog
        fullScreen={fullScreen}
        open={openUploadReportModel && steps.includes(FormStep.UPLOAD_REPORT) && currentStep === FormStep.UPLOAD_REPORT}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={maxWidth}
        fullWidth
      >
        <Box>
          <DialogTitle id="responsive-dialog-title">
            <StepTitle text={generateStepTitle('Upload Reports', FormStep.UPLOAD_REPORT, steps)} />
            <CloseButton handleClose={handleClose} />
          </DialogTitle>
        </Box>
        <DialogContent
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
          dividers
        >
          <Grid container spacing={2}>
            <GridColumn width="6" showDivider="true">
              <Box style={{ paddingRight: '3%', paddingLeft: '3%' }}>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'Left',
                    paddingBottom: '2%',
                    paddingTop: '16px',
                  }}
                  component="h5"
                >
                  New score & position changes
                </Typography>

                <Grid style={{ paddingTop: '0' }} alignItems="flex-start" container spacing={2}>
                  <Grid item md={8} sm={8} xs={6}>
                    <Box
                      style={{
                        textAlign: 'Left',
                        paddingRight: '3%',
                      }}
                    >
                      <TextField
                        id="score"
                        label="Score"
                        type="number"
                        inputRef={newScoreInputRef}
                        defaultValue={score}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        inputProps={{
                          step: 1,
                        }}
                        error={scoreError?.length > 0}
                        helperText={scoreError}
                        onChange={handleScoreChange}
                        disabled="true"
                      />
                    </Box>
                  </Grid>

                  {queuedReports?.length > 0 && (
                    <Grid item md={12} sm={12} xs={12} style={{ paddingTop: '4px' }}>
                      <FormControlLabel
                        label="Ignore queued reports"
                        control={
                          <Checkbox
                            checked={ignoreQueuedReports}
                            onChange={() => {
                              setIgnoreQueuedReports(!ignoreQueuedReports);
                            }}
                          />
                        }
                      />
                    </Grid>
                  )}
                </Grid>

                {showPositionChanges && (
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                    }}
                  >
                    <CompanyPositionChanges
                      topDecile={positionChanges.topDecile}
                      topQuartile={positionChanges.topQuartile}
                      bottomQuartile={positionChanges.bottomQuartile}
                      bottomDecile={positionChanges.bottomDecile}
                      showDivider
                    />
                  </Box>
                )}
              </Box>
            </GridColumn>

            <GridColumn width="6">
              <Box style={{ paddingRight: '3%', paddingLeft: '3%' }}>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'Left',
                    paddingBottom: '2%',
                    paddingTop: '16px',
                  }}
                  component="h5"
                >
                  {upoadReportTitleName}
                </Typography>
                <TextField
                  id="date"
                  label="Report Date"
                  type="date"
                  value={selectedDate}
                  fullWidth
                  onChange={(e) => validateDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box
                style={{
                  paddingTop: '1%',
                  paddingBottom: '1%',
                  paddingRight: '3%',
                  paddingLeft: '3%',
                }}
              >
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="Report Time"
                    type="time"
                    // defaultValue={String(moment().format("HH:mm"))}
                    defaultValue={getTimeZoneValue}
                    key={getTimeZoneValue}
                    // className={classes.textField}
                    onChange={(e) => handleTimeChange(e)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </form>
              </Box>

              <Box
                style={{
                  textAlign: 'Left',
                  paddingBottom: '2%',
                  paddingTop: '5%',
                  paddingRight: '3%',
                  paddingLeft: '3%',
                }}
              >
                <input type="file" label="Browse" accept="application/pdf" onChange={(e) => uploadFile(e)} />
              </Box>

              <Box
                style={{
                  textAlign: 'Left',
                  paddingBottom: '2%',
                  paddingTop: '2%',
                  paddingRight: '3%',
                  paddingLeft: '3%',
                }}
              >
                <FormControl>
                  <FormLabel id="company-notification-label">Notify by email</FormLabel>
                  <RadioGroup
                    value={notificationType}
                    name="company-notification"
                    aria-labelledby="company-notification-label"
                    onChange={(event) => setNotificationType(event.target.value)}
                  >
                    <FormControlLabel
                      value={REPORT_NOTIFICATION_TYPES.ALL_USERS}
                      control={<Radio />}
                      label="Send to all users"
                    />
                    <FormControlLabel
                      value={REPORT_NOTIFICATION_TYPES.SECTORS_AND_COUNTRIES}
                      control={<Radio />}
                      label="Send to specific sectors and countries"
                    />
                    <FormControlLabel
                      value={REPORT_NOTIFICATION_TYPES.DISABLE}
                      control={<Radio />}
                      label="Disable email notification"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <EmailFields
                setSelectedSectors={setSelectedSectors}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                subject={subject}
                setSubject={setSubject}
                mailBody={mailBody}
                setMailBody={setMailBody}
                selectedSectors={selectedSectors}
                sectorsList={sectorsList}
                countryList={countryList}
                sectorName={sectorName}
                notificationType={notificationType}
              />
              <Box
                style={{
                  paddingBottom: '16px',
                }}
              />
            </GridColumn>
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <SecondaryButton text={steps.length > 1 ? 'Back' : 'Cancel'} onClick={handlePrev} />
            <PrimaryButton text="Submit" onClick={handleNext} />
          </>
        </DialogActions>
        <Loader setOpen={setOpen} open={open} />
      </Dialog>

      {openUploadReportModel &&
        steps.includes(FormStep.RECALCULATED_REPORTS) &&
        currentStep === FormStep.RECALCULATED_REPORTS && (
          <RecalculateQueuedReportsStep
            title={generateStepTitle('Recalculated Reports', FormStep.RECALCULATED_REPORTS, steps)}
            reports={queuedReports}
            handleClose={handleClose}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            handlePrev={handlePrev}
            handleNext={handleNext}
            setOpen={setOpen}
            open={open}
            companyId={uploadReportCompanyID}
            companyNewScore={score}
          />
        )}
    </div>
  );
};

export default CompanyUploadReport;
