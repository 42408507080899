import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Tab, Tabs, Card } from '@mui/material';
import { Helmet } from 'react-helmet';

import Header from './header';
import AnalyticsReport from './AnalyticsReport';
import AnalyticsScoreCard from './AnalyticsScoreCard';
import AnalyticsUser from './AnalyticsUser';
// import {BrowserRouter as Router,Route,Link,Switch} from 'react-router-dom';
import Filterglobal from './Filterglobal';
import '../../App.css';

// eslint-disable-next-line react/function-component-definition
export default function Analytics() {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 600,
    },
    alignDivCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      margin: '20px',
    },
    alignMenuRight: {
      position: 'absolute',
      right: '0px',
      marginTop: '-15px',
      // ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
      //   display:'none'
      // }
    },
  }));

  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [filter, setFilter] = useState('All');
  const onTabClicked = (event, i) => {
    setIndex(i);
    setFilter('All');
  };

  return (
    <>
      <Helmet>
        <title>Analytics - Iron Blue Financials</title>
      </Helmet>
      <Header />
      <Card className="cardClass">
        {/* <div> 
           <Box>
        <Button  className="btn btn-dark btn-primary_submit" variant="contained" color="primary" style={{float:"right",borderRadius:"25px",margin:"3px",backgroundColor:"#21309d"}}>
           Analytic By User
        </Button>
        </Box>
        <Box>
        <Button  className="btn btn-dark btn-primary_submit" variant="contained" color="primary" style={{float:"right",borderRadius:"25px",margin:"3px",backgroundColor:"#21309d"}}>
            Analytic By Report
        </Button>
        </Box>
        </div>  */}

        <Typography variant="h4" className="cardTitle" style={{ textSizeAdjust: '2px' }}>
          Analytics
        </Typography>
        <span className="mobile-only">
          <Filterglobal
            data={filter}
            onChangeFilter={(data) => {
              setFilter(data);
            }}
          />
        </span>
      </Card>

      {/* eslint-disable-next-line react/no-unknown-property */}
      <div align="center">
        <span className={classes.alignDivCenter}>
          <Paper className={classes.root} style={{ boxShadow: '5px 7px 15px -5px rgba(0,0,0,0.3)' }}>
            <Tabs
              value={index}
              onChange={onTabClicked}
              indicatorColor="primary"
              variant="fullWidth"
              // textColor="primary"
              centered
              textColor="inherit"
            >
              <Tab style={{ backgroundColor: '#3949ab', color: 'white' }} label="Report" />
              <Tab style={{ backgroundColor: '#3949ab', color: 'white', whiteSpace: 'nowrap' }} label="Score Card" />
              <Tab style={{ backgroundColor: '#3949ab', color: 'white' }} label="User" />
            </Tabs>
          </Paper>
          <span className="alignMenuRight desktop-only">
            <Filterglobal
              data={filter}
              onChangeFilter={(data) => {
                setFilter(data);
              }}
              style={{ marginRight: '50px' }}
            />
          </span>
        </span>

        <AnalyticsReport value={index} index={0} filterValue={filter} />
        <AnalyticsScoreCard value={index} index={1} filterValue={filter} />
        <AnalyticsUser value={index} index={2} filterValue={filter} />
      </div>
    </>
  );
}
