import { Grid } from '@mui/material';

const GridColumn = (props) => {
  const { children, showDivider, width } = props;

  const borderRight = {
    xs: 0,
    sm: 0,
    md: '1px solid #e0e0e0',
    lg: '1px solid #e0e0e0',
    xl: '1px solid #e0e0e0',
  };

  const borderBottom = {
    xs: '1px solid #e0e0e0',
    sm: '1px solid #e0e0e0',
    md: 0,
    lg: 0,
    xl: 0,
  };

  return (
    <Grid
      item
      sm={12}
      md={width}
      xl={width}
      xs={12}
      sx={{
        borderRight: showDivider ? borderRight : null,
        borderBottom,
      }}
    >
      {children}
    </Grid>
  );
};

export default GridColumn;
