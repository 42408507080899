import { useState, useEffect } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import Loader from '../BackdropLoader';
import CompanyPositionChanges from '../CompanyPositionChanges';
import AxiosInstance from '../../../api/axios';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import StepTitle from './StepTitle';
import CloseButton from './CloseButton';
import GridColumn from './GridColumn';
import ScoreCardReportsTable from './ScoreCardReportsTable';
import { reportStatus } from '../../../utils/constants';
import { isNumeric } from '../../../utils';
import { TOAST_OPTIONS } from '../../../utils/toast';

const SCORE_FIELDS = [
  {
    label: 'Revenue recognition',
    name: 'revenue_recognition',
  },
  {
    label: 'Cost recognition',
    name: 'cost_recognition',
  },
  {
    label: 'Net debt',
    name: 'net_debt',
  },
  {
    label: 'Pension schemes',
    name: 'pension_schemes',
  },
  {
    label: 'JVs & Associates',
    name: 'jvs_and_associates',
  },
  {
    label: 'Acquisitions',
    name: 'acquisitions',
  },
  {
    label: 'Non-GAAP measures',
    name: 'non_gaap_measures',
  },
  {
    label: 'ACCOUNTING',
    name: 'accounting',
    readOnly: true,
    calculate: (formData) => {
      return [
        'revenue_recognition',
        'cost_recognition',
        'net_debt',
        'pension_schemes',
        'jvs_and_associates',
        'acquisitions',
        'non_gaap_measures',
      ].reduce((sum, key) => {
        return sum + (formData[key] || 0);
      }, 0);
    },
  },
  {
    label: 'Audit',
    name: 'audit',
  },
  {
    label: 'Management',
    name: 'management',
  },
  {
    label: 'GOVERNANCE',
    name: 'governance',
    readOnly: true,
    calculate: (formData) => {
      return ['audit', 'management'].reduce((sum, key) => {
        return sum + (formData[key] || 0);
      }, 0);
    },
  },
  {
    label: 'DISCLOSURES',
    name: 'disclosures',
  },
  {
    label: 'RISKS',
    name: 'risks',
  },
  {
    label: 'TOTAL SCORE',
    name: 'latest_score',
    readOnly: true,
    calculate: (formData) => {
      return ['accounting', 'governance', 'disclosures', 'risks'].reduce((sum, key) => {
        return sum + (formData[key] || 0);
      }, 0);
    },
  },
];

const ScoreCardStep = (props) => {
  const {
    title,
    fullScreen,
    handleClose,
    maxWidth,
    canNavigateBack,
    handlePrev,
    handleNext,
    setOpen,
    open,
    companyId,
    setScore,
    scoreCardMap,
    setScoreCardMap,
    positionChanges,
    removeSpreadsheetFromNotification,
    setRemoveSpreadsheetFromNotification,
    showPositionChanges,
    setShowPositionChanges,
  } = props;

  const [reports, setReports] = useState(null);

  /**
   * Format text field value to number
   */

  const formatFieldValue = (fieldName, value) => {
    if (value === '') {
      return null;
    }

    if (isNumeric(value)) {
      return Number(value);
    }

    if (isNumeric(scoreCardMap[fieldName])) {
      return scoreCardMap[fieldName];
    }

    return null;
  };

  /**
   * Get the value of the input text field
   */

  const getTextFieldValue = (fieldName) => {
    return isNumeric(scoreCardMap[fieldName]) ? scoreCardMap[fieldName] : '';
  };

  /**
   * Get the error state for the input text field
   */

  const getTextFieldErrorState = (fieldName) => {
    return fieldName in scoreCardMap && scoreCardMap[fieldName] === null;
  };

  /**
   * Validate form fields
   */

  const validate = () => {
    let isValid = true;

    SCORE_FIELDS.forEach(({ name }) => {
      const value = scoreCardMap[name];
      if (value === null || !isNumeric(value)) {
        isValid = false;
        scoreCardMap[name] = null;
      }
    });

    setScoreCardMap({ ...scoreCardMap });

    if (!isValid) {
      toast.error('Please ensure all fields are filled in to continue', TOAST_OPTIONS);
    }

    return isValid;
  };

  /**
   * Handle text field change event
   */

  const handleTextFieldChange = (event, fieldName) => {
    const { value } = event.target;

    const newValue = formatFieldValue(fieldName, value);

    scoreCardMap[fieldName] = newValue;

    SCORE_FIELDS.filter((field) => field.calculate).forEach((field) => {
      scoreCardMap[field.name] = field.calculate(scoreCardMap);
    });

    setScoreCardMap({ ...scoreCardMap });
  };

  /**
   * Apply score changes
   */

  const handleApplyScore = () => {
    if (!validate()) {
      return;
    }

    setShowPositionChanges(true);

    if (scoreCardMap.latest_score) {
      setScore(scoreCardMap.latest_score);
    }
  };

  /**
   * Validate form fields and navigate to the next step
   */

  const validateAndHandleNext = () => {
    if (!validate()) {
      return;
    }

    handleApplyScore();
    setShowPositionChanges(true);
    handleNext();
  };

  /**
   * Perform initial data load
   */

  useEffect(() => {
    const loadQueuedReports = async () => {
      try {
        const { data } = await AxiosInstance.get(`admin/reports?status=${reportStatus.PUBLISH}`);
        const newReports = data.data.filter((report) => report.company_id === companyId);
        setReports(newReports);
      } catch (error) {
        console.log('==>Errr sector', error);
      }
    };

    loadQueuedReports();
  }, [companyId]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            // maxWidth: '640px',
            maxHeight: '750px',
          },
        },
      }}
    >
      <Box>
        <DialogTitle id="responsive-dialog-title">
          <StepTitle text={title} />
          <CloseButton handleClose={handleClose} />
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        dividers
      >
        <Grid container spacing={2}>
          <GridColumn width="6" showDivider="true">
            <Box style={{ paddingRight: '3%', paddingLeft: '3%' }}>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'Left',
                  paddingBottom: '2%',
                  paddingTop: '16px',
                }}
                component="h5"
              >
                New report
              </Typography>
            </Box>
            <Box style={{ paddingRight: '3%', paddingLeft: '3%' }}>
              <FormControlLabel
                label="Remove scorecard spreadsheet from notification"
                control={
                  <Checkbox
                    checked={removeSpreadsheetFromNotification}
                    onChange={() => {
                      setRemoveSpreadsheetFromNotification(!removeSpreadsheetFromNotification);
                    }}
                  />
                }
              />
              <Grid container spacing={2}>
                {_.chunk(SCORE_FIELDS, SCORE_FIELDS.length / 2).map((chunk, index) => (
                  <Grid item xs={6} key={index}>
                    {chunk.map((field) => (
                      <Grid item xs={12} key={field.name} className="mt-1">
                        <TextField
                          fullWidth
                          label={field.label}
                          name={field.name}
                          value={getTextFieldValue(field.name)}
                          onChange={(event) => handleTextFieldChange(event, field.name)}
                          disabled={field.readOnly}
                          required
                          error={getTextFieldErrorState(field.name)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Box style={{ paddingTop: '3%', paddingBottom: '3%' }}>
                <Button
                  onClick={handleApplyScore}
                  className=" btn btn-dark btn-primary_submit submitButton shadow-none"
                  color="primary"
                  autoFocus
                  style={{ marginTop: '10px', whiteSpace: 'nowrap' }}
                >
                  <span className="MuiButton-label">Apply Score</span>
                </Button>
                {!showPositionChanges && (
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                    }}
                  >
                    <Box style={{ paddingBottom: '16px', marginTop: '20px' }}>
                      <Typography
                        style={{
                          color: '#000000',
                        }}
                        variant="body"
                        component="p"
                      >
                        <span>Please apply the new score to see the changes</span>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {showPositionChanges && (
                  <CompanyPositionChanges
                    topDecile={positionChanges.topDecile}
                    topQuartile={positionChanges.topQuartile}
                    bottomQuartile={positionChanges.bottomQuartile}
                    bottomDecile={positionChanges.bottomDecile}
                    showDivider
                  />
                )}
              </Box>
            </Box>
          </GridColumn>
          <GridColumn width="6">
            <Box style={{ paddingRight: '3%', paddingLeft: '3%' }}>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'Left',
                  paddingBottom: '2%',
                  paddingTop: '16px',
                }}
                component="h5"
              >
                Published reports
              </Typography>
            </Box>
            <Box style={{ paddingRight: '3%', paddingBottom: '3%', paddingLeft: '3%' }}>
              <ScoreCardReportsTable reports={reports} scoreFields={SCORE_FIELDS} />
            </Box>
          </GridColumn>
        </Grid>
      </DialogContent>
      <DialogActions>
        <>
          <SecondaryButton text={canNavigateBack() ? 'Back' : 'Cancel'} onClick={handlePrev} />
          <PrimaryButton text="Proceed" onClick={validateAndHandleNext} />
        </>
      </DialogActions>
      <Loader setOpen={setOpen} open={open} />
    </Dialog>
  );
};

export default ScoreCardStep;
