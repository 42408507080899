import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  cell: {
    padding: '8px !important',
    lineHeight: '1',
    fontWeight: '300',
  },
  cellBackground: {
    backgroundColor: 'rgba(217, 217, 217, 0.5)',
  },
  cellBoldText: {
    fontWeight: 'bold',
  },
}));

const ScoreCardReportsTable = (props) => {
  const { reports, scoreFields } = props;

  const classes = useStyles();

  const generateCellClassName = (isLast, fieldName) => {
    const classList = [classes.cell];
    if (isLast) {
      classList.push(classes.cellBackground);
    }
    if (isLast && ['accounting', 'governance', 'disclosures', 'risks', 'latest_score'].includes(fieldName)) {
      classList.push(classes.cellBoldText);
    }

    return classList;
  };

  return (
    <div
      style={{
        overflowX: 'auto',
      }}
    >
      {!reports?.length && (
        <Typography
          style={{
            padding: '40px',
            textAlign: 'center',
          }}
        >
          No reports available
        </Typography>
      )}
      {reports?.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow loader="bubble-spin" color="#21309D">
              <TableCell size="small" align="center" className={classes.cell}>
                Report Date
              </TableCell>
              {reports.map((report, index) => (
                <TableCell
                  size="small"
                  align="center"
                  className={generateCellClassName(index === reports.length - 1, 'date_change')}
                >
                  {moment(report.date_change).format('YYYY-MM-DD')}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scoreFields.map((field) => (
              <TableRow>
                <TableCell key={field.name} size="small" align="center" className={classes.cell}>
                  {field.label}
                </TableCell>
                {reports.map((report, index) => (
                  <TableCell
                    align="center"
                    size="small"
                    className={generateCellClassName(index === reports.length - 1, field.name)}
                  >
                    {report[field.name]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ScoreCardReportsTable;
