import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  IconButton,
  TableSortLabel,
  TableContainer,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { useRef, useEffect, useState, useMemo } from 'react';
import {
  LATEST_SCORE,
  REPORT_DATE,
  COMPANY,
  RIC,
  ACCOUNTING,
  ACQUISITIONS,
  AUDIT,
  COST_RECOGNITION,
  DISCLOSURES,
  GOVERNANCE,
  JVS_AND_ASSOCIATES,
  MANAGEMENT,
  NET_DEBT,
  NON_GAAP_MEASURES,
  PENSION_SCHEMES,
  REVENUE_RECOGNITION,
  RISKS,
  TABLE_HEAD,
} from './constants';

import { formattedDate } from './utils';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '1024px',
    '& thead th': {
      fontWeight: '600',
    },
    '& tbody td': {
      fontWeight: '300',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '5px',
    height: '100%',
  },
  noRecordsMessage: {
    padding: '20px',
    textAlign: 'center',
  },
  innerCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '5px',
  },
  extendedHeadChild: {
    backgroundColor: theme.palette.grey['200'],
  },
  extendedBodyChild: {
    backgroundColor: 'rgba(33,47,157, 0.04)',
  },
}));

const ScoreCardTable = (props) => {
  const { reports, order, orderBy, setOrder, setOrderBy } = props;

  const classes = useStyles();
  const [extendedColumns, setExtendedColumns] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tableRef = useRef();

  const tableColumns = TABLE_HEAD;

  const toggleSort = (name) => {
    if (orderBy === name) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrder('desc');
    }
    setOrderBy(name);
  };

  const reportIds = JSON.stringify(reports.map((report) => report.id));

  const data = useMemo(() => {
    return reports.map((report) => {
      const {
        id,
        date_change: dateChange,
        latest_score: latestScore,
        revenue_recognition: revenueRecognition,
        cost_recognition: costRecognition,
        net_debt: netDebt,
        pension_schemes: pensionSchemes,
        jvs_and_associates: jvsAndAssociates,
        acquisitions,
        non_gaap_measures: nonGaapMeasures,
        accounting,
        audit,
        management,
        governance,
        disclosures,
        risks,
        company: { name: companyName, ric_code: ricCode },
      } = report;

      return {
        id,
        [REPORT_DATE]: formattedDate(dateChange),
        [COMPANY]: companyName,
        [RIC]: ricCode,
        [ACCOUNTING]: accounting,
        [REVENUE_RECOGNITION]: revenueRecognition,
        [COST_RECOGNITION]: costRecognition,
        [NET_DEBT]: netDebt,
        [PENSION_SCHEMES]: pensionSchemes,
        [JVS_AND_ASSOCIATES]: jvsAndAssociates,
        [ACQUISITIONS]: acquisitions,
        [NON_GAAP_MEASURES]: nonGaapMeasures,
        [GOVERNANCE]: governance,
        [AUDIT]: audit,
        [MANAGEMENT]: management,
        [DISCLOSURES]: disclosures,
        [RISKS]: risks,
        [LATEST_SCORE]: latestScore,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportIds]);

  const getStickyStyles = ({ name, isHead }) => {
    const zIndex = isHead ? 4 : 3;
    if (!isMobile) {
      if (name === REPORT_DATE) {
        return { position: 'sticky', left: '0', background: 'white', zIndex };
      }
      if (name === COMPANY) {
        return { position: 'sticky', left: 'var(--col1-width)', background: 'white', zIndex };
      }
      if (name === RIC) {
        return {
          position: 'sticky',
          left: 'calc(var(--col1-width) + var(--col2-width))',
          borderRight: '1px solid rgb(224, 224, 224)',
          background: 'white',
          zIndex,
        };
      }
    }

    return {};
  };

  const stickyClasses = (name) => {
    if (name === REPORT_DATE) {
      return 'sticky-1';
    }
    if (name === COMPANY) {
      return 'sticky-2';
    }

    return '';
  };

  const createHeadCell = ({ extend, label, name, isChild }) => {
    const isExtended = extendedColumns.includes(name);
    const textContent = (
      <Typography color="black" variant="inherit" lineHeight="1.1">
        {label}
      </Typography>
    );

    const stickyStyles = getStickyStyles({ name, isHead: true });

    return (
      <>
        {isExtended && extend.map((ext) => createHeadCell({ ...ext, isChild: true }))}
        <TableCell
          key={name}
          className={`${isChild ? classes.extendedHeadChild : ''} ${stickyClasses(name)}`}
          sx={{
            ...(name === REPORT_DATE ? { whiteSpace: 'nowrap' } : {}),
            ...stickyStyles,
          }}
        >
          <Box className={classes.innerCell}>
            <TableSortLabel
              active={orderBy === name}
              direction={orderBy === name ? order : 'desc'}
              onClick={() => {
                toggleSort(name);
              }}
              sx={{
                '&:hover': {
                  opacity: '0.7',
                },
                marginLeft: extend ? '30px' : '20px',
              }}
            >
              {textContent}
              {extend && (
                <IconButton
                  sx={{
                    padding: 0,
                    marginLeft: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isExtended) {
                      setExtendedColumns((prev) => prev.filter((ext) => ext !== name));
                    } else {
                      setExtendedColumns((prev) => [...prev, name]);
                    }
                  }}
                >
                  {!isExtended ? (
                    <AddBoxOutlinedIcon color="black" />
                  ) : (
                    <IndeterminateCheckBoxOutlinedIcon color="black" />
                  )}
                </IconButton>
              )}
            </TableSortLabel>
          </Box>
        </TableCell>
      </>
    );
  };

  const createBodyCell = ({ extend, name, isChild }, item) => {
    const isExtended = extendedColumns.includes(name);

    const stickyStyles = getStickyStyles({ name });
    return (
      <>
        {isExtended && extend.map((ext) => createBodyCell({ ...ext, isChild: true }, item))}
        <TableCell
          key={name}
          color="primary"
          className={`${isChild ? classes.extendedBodyChild : ''} ${stickyClasses(name)}`}
          sx={{
            ...stickyStyles,
            cursor: 'default',
          }}
        >
          <Box className={classes.innerCell}>
            <Typography color="black" variant="inherit">
              {item[name]}
            </Typography>
          </Box>
        </TableCell>
      </>
    );
  };

  const updateStickyWidths = () => {
    if (!tableRef.current) return;

    const table = tableRef.current;
    const col1 = table.querySelectorAll('.sticky-1');
    const col2 = table.querySelectorAll('.sticky-2');

    const col1Width = `${Math.max(...Array.from(col1).map((cell) => cell.offsetWidth))}px`;
    const col2Width = `${Math.max(...Array.from(col2).map((cell) => cell.offsetWidth))}px`;

    table.style.setProperty('--col1-width', col1Width);
    table.style.setProperty('--col2-width', col2Width);
  };

  const extendedNames = JSON.stringify(extendedColumns);

  useEffect(() => {
    updateStickyWidths();
  }, [extendedNames]);

  useEffect(() => {
    updateStickyWidths();
    window.addEventListener('resize', updateStickyWidths);

    return () => window.removeEventListener('resize', updateStickyWidths);
  }, []);

  return reports?.length ? (
    <Box className={classes.tableWrapper}>
      <TableContainer sx={{ overflow: 'auto', height: '100%' }}>
        <Table className={classes.table} stickyHeader ref={tableRef}>
          <TableHead>
            <TableRow color="#21309D">{tableColumns.map(createHeadCell)}</TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>{tableColumns.map((headItem) => createBodyCell(headItem, item))}</TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Typography className={classes.noRecordsMessage}>No Data Found</Typography>
  );
};

ScoreCardTable.propTypes = {
  reports: PropTypes.arrayOf(),
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
};

export default ScoreCardTable;
