import { useState } from 'react';
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';

import 'alertifyjs/build/css/alertify.css';
import AxiosInstance from '../../../api/axios';
import { TOAST_OPTIONS } from '../../../utils/toast';

const UserScoreCardNotificationSwitch = (props) => {
  const { user, loadUser } = props;

  const [isChecked, setIsChecked] = useState(user.scorecard_notification === 1);

  const handleChange = async () => {
    const newValue = !isChecked;

    try {
      const url = `user/scorecard_notification/${user.id}`;
      const response = await AxiosInstance.put(url, { value: newValue }, { headers: { Accept: 'application/json' } });
      const { message } = response.data;
      loadUser();
      setIsChecked(newValue);
      toast.info(message, TOAST_OPTIONS);
    } catch (error) {
      toast.error('Something went wrong', TOAST_OPTIONS);
    }
  };

  return <Switch checked={isChecked} onChange={handleChange} color="primary" />;
};

export default UserScoreCardNotificationSwitch;
