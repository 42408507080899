export const REPORT_DATE = 'date_change';
export const COMPANY = 'company.name';
export const ACCOUNTING = 'accounting';
export const REVENUE_RECOGNITION = 'revenue_recognition';
export const COST_RECOGNITION = 'cost_recognition';
export const NET_DEBT = 'net_debt';
export const PENSION_SCHEMES = 'pension_schemes';
export const JVS_AND_ASSOCIATES = 'jvs_and_associates';
export const ACQUISITIONS = 'acquisitions';
export const NON_GAAP_MEASURES = 'non_gaap_measures';
export const GOVERNANCE = 'governance';
export const AUDIT = 'audit';
export const MANAGEMENT = 'management';
export const DISCLOSURES = 'disclosures';
export const RISKS = 'risks';
export const LATEST_SCORE = 'latest_score';
export const RIC = 'company.ric_code';

export const STICKY_DESKTOP_COLUMNS = [COMPANY, REPORT_DATE, RIC];

export const TABLE_HEAD = [
  {
    label: 'Report date',
    name: REPORT_DATE,
  },
  {
    label: 'Company',
    name: COMPANY,
  },
  {
    label: 'RIC',
    name: RIC,
  },
  {
    label: 'Accounting',
    name: ACCOUNTING,
    extend: [
      {
        label: 'Revenue recognition',
        name: REVENUE_RECOGNITION,
      },
      {
        label: 'Cost recognition',
        name: COST_RECOGNITION,
      },
      {
        label: 'Net debt',
        name: NET_DEBT,
      },
      {
        label: 'Pension schemes',
        name: PENSION_SCHEMES,
      },
      {
        label: 'JVs & Associates',
        name: JVS_AND_ASSOCIATES,
      },
      {
        label: 'Acquisitions',
        name: ACQUISITIONS,
      },
      {
        label: 'Non-GAAP measures',
        name: NON_GAAP_MEASURES,
      },
    ],
  },
  {
    label: 'Governance',
    name: GOVERNANCE,
    extend: [
      {
        label: 'Audit',
        name: AUDIT,
      },
      {
        label: 'Management',
        name: MANAGEMENT,
      },
    ],
  },
  {
    label: 'Disclosures',
    name: DISCLOSURES,
  },
  {
    label: 'Risks',
    name: RISKS,
  },
  {
    label: 'Total score',
    name: LATEST_SCORE,
  },
];
