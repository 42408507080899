import { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import alertify from 'alertifyjs';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../../../api/axios';
import 'alertifyjs/build/css/alertify.css';
import { TOAST_OPTIONS } from '../../../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function UserApprovalStatusSwitch(props) {
  const { item, loadUser } = props;

  const [checked, setChecked] = useState(false);
  const [checkValue, setCheckValue] = useState('0');

  useEffect(() => {
    if (item.is_verified === 0) {
      setChecked(true);
      setCheckValue('1');
    } else {
      setChecked(false);
      setCheckValue('0');
    }
    // loadUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => {
    if (item.is_verified === 0) {
      setChecked(true);
      setCheckValue('1');
    } else {
      setChecked(false);
      setCheckValue('0');
    }

    alertify
      .confirm(
        'User Approval Status',
        'Do you really want to change user approval status?',
        function () {
          if (item != null) {
            const userId = item.id;
            const url = `user/approve/${userId}`;
            AxiosInstance.put(
              url,
              {
                status: checkValue,
              },
              {
                headers: { Accept: 'application/json' },
              },
            )
              .then(function (response) {
                const { message } = response.data;
                loadUser();
                toast.info(message, TOAST_OPTIONS);
              })
              .catch(function (error) {
                toast.error('Something went wrong', TOAST_OPTIONS);
                //    setError(error)
              });
          } else {
            toast.error('Something Went wrong', TOAST_OPTIONS);
          }
        },
        function () {},
      )
      .set('labels', { ok: 'Yes', cancel: 'No' });
  };

  return <Switch checked={!!item.is_verified} onChange={handleChange} name="checkedB" color="primary" />;
}
