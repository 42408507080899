import _ from 'lodash';
import moment from 'moment';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';

import CompanyPositionChanges from '../CompanyPositionChanges';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import GridColumn from './GridColumn';
import StepTitle from './StepTitle';
import CloseButton from './CloseButton';

const QueuedReportsStep = (props) => {
  const { title, reports, fullScreen, handleClose, maxWidth, handlePrev, handleNext } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '640px',
            maxHeight: '750px',
          },
        },
      }}
    >
      <Box>
        <DialogTitle id="responsive-dialog-title">
          <StepTitle text={title} />
          <CloseButton handleClose={handleClose} />
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        dividers
      >
        <Grid container>
          <GridColumn width="12">
            {reports.map((report, index) => {
              const reportTime = moment(`${report.date_change.split('T')[0]} ${report.time}`);
              return (
                <Box
                  style={{
                    textAlign: 'Left',
                    paddingRight: '3%',
                    marginTop: '30px',
                    paddingLeft: '2%',
                  }}
                >
                  <span style={{ fontWeight: '600' }}>
                    A report on company {report.company.name} with a score change from {report.company.latest_score} to{' '}
                    {report.latest_score} is already submitted for publication on{' '}
                    {reportTime.format('YYYY-MM-DD HH:mm')} GMT, which will make the following changes:
                  </span>
                  <CompanyPositionChanges
                    topDecile={report.position_changes?.topDecile || 'No changes'}
                    topQuartile={report.position_changes?.topQuartile || 'No changes'}
                    bottomQuartile={report.position_changes?.bottomQuartile || 'No changes'}
                    bottomDecile={report.position_changes?.bottomDecile || 'No changes'}
                    showDivider={false}
                  />
                  {index < reports.length - 1 && <Divider style={{ borderColor: '#8c8b8b' }} />}
                </Box>
              );
            })}
          </GridColumn>
        </Grid>
      </DialogContent>
      <DialogActions>
        <>
          <SecondaryButton text="Cancel" onClick={handlePrev} />
          <PrimaryButton text="Proceed" onClick={handleNext} />
        </>
      </DialogActions>
    </Dialog>
  );
};

export default QueuedReportsStep;
