import { Typography } from '@mui/material';

const StepTitle = (props) => {
  const { text } = props;

  return (
    <Typography
      variant="h6"
      style={{
        textAlign: 'Left',
        paddingBottom: '10px',
        paddingLeft: '1%',
        paddingRight: '3%',
      }}
      component="span"
    >
      {text}
    </Typography>
  );
};

export default StepTitle;
