import { useEffect, useState } from 'react';
import { Box, Typography, Card, Paper, CircularProgress, Snackbar, Alert, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import pickBy from 'lodash/pickBy';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';

import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../../api/axios';
import ScoreCardTable from './ScoreCardTable';
import { getAccessToken } from '../../utils/index';
import { REPORT_DATE } from './constants';

const useStyles = makeStyles((theme) => ({
  pageWrapper: { display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' },
  wrapper: {
    overflow: 'hidden',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 769px)': {
      padding: '20px',
    },
  },
  wrapperPaper: {
    boxShadow: 'none',
  },
  wrapperTable: {
    marginTop: '40px',
    position: 'relative',
    overflow: 'hidden',
    flexGrow: '1',
  },
  tableLoader: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ScoreCardContent = () => {
  const classes = useStyles();
  const [isInitialized, setIsInitialized] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(REPORT_DATE);

  const getReports = async () => {
    setShowLoader(true);
    try {
      const params = { page: 1, limit: 999, orderBy, order, distinctBy: 'company' };
      const searchParams = new URLSearchParams(pickBy(params, (value) => Boolean(value)));
      const { data } = await AxiosInstance.get(`reports?${searchParams.toString()}`);
      setReports(data.data);
      setShowLoader(false);
    } catch (e) {
      setShowLoader(false);
      setError('Something went wrong');
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.wrapperPaper}>
        <Card className="cardClass" style={{ margin: '0', flexWrap: 'wrap', gap: '10px' }}>
          <Typography variant="h4" className="cardTitle">
            Scorecard
          </Typography>
          <Button
            variant="contained"
            startIcon={<DownloadIcon sx={{ color: 'white !important' }} />}
            component={Link}
            sx={{ borderRadius: '35px' }}
            target="_blank"
            download
            to={`${GOOGLE_CLOUD_BASE_URL}scorecard?token=${getAccessToken()}`}
          >
            <Typography fontWeight={700} color="white" sx={{ textTransform: 'initial' }}>
              Download EXCEL
            </Typography>
          </Button>
        </Card>
      </Paper>

      <Paper className={classes.wrapperTable}>
        {isInitialized && (
          <ScoreCardTable
            reports={reports}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
        )}
        {showLoader && (
          <Box className={classes.tableLoader}>
            <CircularProgress />
          </Box>
        )}

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={() => {
            setError('');
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setError('');
            }}
            severity="error"
            variant="filled"
          >
            {error}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default ScoreCardContent;
