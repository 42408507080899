import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Card, Paper, TableBody, TableRow, TableCell, Tooltip, Chip } from '@mui/material';
// import AdminSectorForm from "./AddSectorForm";
import { Edit as EditIcon, DeleteOutline as DeleteOutlineIcon, VpnKey as VpnKeyIcon } from '@mui/icons-material';
// import ConfirmationDialog from '.Analytics./ConfirmationModel';
import alertify from 'alertifyjs';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import 'alertifyjs/build/css/alertify.css';

import '../../../App.css';
import ForgotPassword from './AdminForgotPass';
import UserScoreCardNotificationSwitch from './UserScoreCardNotificationSwitch';
import UserApprovalStatusSwitch from './UserApprovalStatusSwitch';
import AxiosInstance from '../../../api/axios';
import Loader from '../BackdropLoader';
import SectorTable from './UserAdminTable';
import Popup from './UserEditPopUp';
import Header from '../header';
import EmptyComponent from '../emptyComponent';
import { TOAST_OPTIONS } from '../../../utils/toast';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCellContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noClick: {
    cursor: 'initial',
  },
  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  head: {
    // backgroundColor: "#fff",
    // position: "sticky",
    // top: 0
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  line1: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width:"10%",
    // display: "-webkit-box",
    // display: "inline-flex",
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      overflow: 'initial',
      wordBbreak: 'break-all',
    },
  },
}));

const headCells = [
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'user_company', label: 'Company' },
  { id: 'sector', label: 'Sector', width: 12 },
  { id: 'country', label: 'Country', width: 12 },
  { id: 'scorecard_notification', label: 'Scorecard Notification', width: 8 },
  { id: 'is_verified', label: 'Approval Status' },
  { id: 'forgetPassword', label: 'Reset Password', disableSorting: true },
  { id: 'manage', label: 'Manage', disableSorting: true, width: 4 },
];

// eslint-disable-next-line react/function-component-definition
export default function Sectors() {
  // const [sectors, setSectors] = useState([]);
  const [Users, setUsers] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openUserPopup, setopenUserPopup] = useState(false);
  const [editUserRecord, seteditUserRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [userRecord, setUserRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [open, setOpen] = useState(false);
  const [initalSectorValue, setInitialSectorValue] = useState([]);
  const [initalCountryValue, setInitialCountryValue] = useState([]);

  const loadUser = async () => {
    setOpen(true);
    AxiosInstance.get('user')
      .then(function (response) {
        const finalRes = response.data.data;
        finalRes.map((item) => ({ ...item, viewSectorMore: false, viewCountryMore: false }));
        setUsers(finalRes);
        setOpen(false);
      })
      .catch(function (error) {
        setOpen(false);
      });
  };
  useEffect(() => {
    loadUser();
  }, []);
  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  } = SectorTable(Users, headCells, filterFn);

  const openConfirmationModel = (item) => {
    setUserRecord(item);
    setOpenDeleteModel(true);
  };

  const openUserEditInPopUp = (item) => {
    seteditUserRecord(item);
    const arrayValueSector = _.map(item.sector, 'name');
    const arrayValueCountry = _.map(item.country, 'name');
    setInitialSectorValue(arrayValueSector);
    setInitialCountryValue(arrayValueCountry);
    setopenUserPopup(true);
  };

  const uploadReportModel = (item) => {
    setUploadReport(item);
    const titleName = `Sector: ${item.name}`;
    setUploadReportTitleName(titleName);
    setUploadReportModel(true);
  };

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage = message.response.data.message;
    const code = message.response.status;

    if (displayMessage === 'undefined') {
      toast.error('Something went wrong', TOAST_OPTIONS);
    } else if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };
  const deleteUserModel = (item) => {
    alertify.confirm(
      'Delete User',
      'Do you really want to delete user?',
      function () {
        if (item != null) {
          const userId = item.id;
          AxiosInstance.delete(`user/${userId}`, {
            headers: { Accept: 'application/json' },
          })
            .then(function (response) {
              const code = response.status;
              const { message } = response.data;
              notificationMessage(message, code);
              // toast.success(message, TOAST_OPTIONS);
              setOpen(false);
              loadUser();
            })
            .catch(function (error) {
              errorNotification(error, 500);
              setOpenDeleteModel(false);
            });
        } else {
          toast.error('Something Went wrong', TOAST_OPTIONS);
        }
      },
      function () {},
    );
  };

  const changeViewMoreToggle = (index, key) => {
    const finalRes = _.cloneDeep(Users);
    const status = finalRes[index][key];
    finalRes[index][key] = !status;
    setUsers(finalRes);
  };

  return (
    <>
      <Helmet>
        <title>Users - Iron Blue Financials</title>
      </Helmet>
      <Header />
      <Loader setOpen={setOpen} open={open} />

      <Card className="cardClass">
        <Typography variant="h4" className="cardTitle">
          Users
        </Typography>
      </Card>

      {Users && Users.length ? (
        <Box textAlign="center">
          <Paper className="TableView">
            <TablContainer>
              <TblHead />
              <TableBody align="center">
                {stableSort(Users, getComparator(order, orderBy)).map((item, index) => (
                  <TableRow align="center" key={item.id}>
                    <TableCell className="FixColumn" align="center">
                      {item.first_name}
                    </TableCell>
                    <TableCell className="FixColumn" align="center">
                      {item.last_name}
                    </TableCell>
                    <TableCell align="center">{item.email}</TableCell>
                    <TableCell className="FixColumn" align="center">
                      {item.user_company}
                    </TableCell>
                    <TableCell>
                      <div className={classes.tableCellContent}>
                        <div className={!item.viewSectorMore ? 'chips-layout' : 'chips-layout-open'}>
                          {item && item.sector && item.sector.length
                            ? item.sector.map((value, i) => (
                                <Chip key={`${item.id}-${i}`} label={value.name} className={classes.chip} />
                              ))
                            : '-'}
                        </div>
                        {item && item.sector && item.sector.length > 1 ? (
                          <span
                            className={['view-more']}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              changeViewMoreToggle(index, 'viewSectorMore');
                            }}
                            onKeyPress={() => {}}
                          >
                            {item.viewSectorMore ? 'Show Less' : 'Show More'}
                          </span>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.tableCellContent}>
                        <div className={!item.viewSectorMore ? 'chips-layout' : 'chips-layout-open'}>
                          {item && item.country && item.country.length
                            ? item.country.map((value, i) => (
                                <Chip key={`${item.id}-${i}`} label={value.name} className={classes.chip} />
                              ))
                            : '-'}
                        </div>
                        {item && item.country && item.country.length > 1 ? (
                          <span
                            className="view-more"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              changeViewMoreToggle(index, 'viewSectorMore');
                            }}
                            onKeyPress={() => {}}
                          >
                            {item.viewSectorMore ? 'Show Less' : 'Show More'}
                          </span>
                        ) : null}
                      </div>
                    </TableCell>
                    {/* Add New */}
                    {/* <Grid item xs={12} >
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-chip-label">Select Country</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-chip-label"
                                                    id="demo-mutiple-chip"
                                                    multiple
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    input={<Input id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <div className={classes.chips}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} className={classes.chip} />
                                                            ))}
                                                        </div>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                     <div className={classes.chips}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} className={classes.chip} />
                                                            ))}
                                                        </div>
                                                    {countryList.map((item) => (
                                                        <MenuItem key={item.id} value={item.name} style={getStyles(item, sectorName, theme)}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                         </Grid> */}
                    {/* End New */}
                    <TableCell align="center">
                      <UserScoreCardNotificationSwitch user={item} loadUser={loadUser} />
                    </TableCell>
                    <TableCell align="center">
                      <UserApprovalStatusSwitch item={item} loadUser={loadUser} />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Reset Password" style={{ margin: '2%' }} arrow>
                        <VpnKeyIcon
                          onClick={() => {
                            openConfirmationModel(item);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Edit" arrow>
                        <EditIcon
                          onClick={() => {
                            openUserEditInPopUp(item);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <DeleteOutlineIcon
                          onClick={() => {
                            deleteUserModel(item);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      ) : (
        !open && <EmptyComponent />
      )}
      <Popup
        openUserPopup={openUserPopup}
        setopenUserPopup={setopenUserPopup}
        editUserRecord={editUserRecord}
        initalSectorValue={initalSectorValue}
        initalCountryValue={initalCountryValue}
        loadUser={loadUser}
        setOpen={setOpen}
        open={open}
      />
      <ForgotPassword
        userRecord={userRecord}
        openDeleteModel={openDeleteModel}
        setOpenDeleteModel={setOpenDeleteModel}
        loadUser={loadUser}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
