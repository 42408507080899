import { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Grid,
  TextField,
  Box,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  FormGroup,
  Select,
  Chip,
} from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';
import _without from 'lodash/without';
import CancelIcon from '@mui/icons-material/Cancel';
import AxiosInstance from '../../../api/axios';
import useForm from '../UseForm';
import { TOAST_OPTIONS } from '../../../utils/toast';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '3%',
      // width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
const initialSelected = ['April Tucker', 'Ralph Hubbard'];

function getStyles(name, sectorName, theme) {
  return {
    fontWeight:
      sectorName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

// eslint-disable-next-line react/function-component-definition
export default function UserEditForm(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    addOrEdit,
    editUserRecord,
    loadSector,
    openPopup,
    setOpenPopUp,
    setOpen,
    setopenUserPopup,
    open,
    initalSectorValue,
    initalCountryValue,
    loadUser,
  } = props;
  const [sectorName, setSectorName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSectors, setSelectedSectors] = useState(initalSectorValue);
  const [sectorsList, setSectorList] = useState([{}]);
  const [countryList, setCountryList] = useState([{}]);
  const [selectedCountry, setSelectedCountry] = useState(initalCountryValue);
  const [selectedCountryId, setSelectedCountryId] = useState([]);
  const [userFirstName, setuserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userCompany, setUserCompany] = useState('');
  const [scoreCardNotification, setScoreCardNotification] = useState(false);

  const initialFValues = {
    name: '',
    latest_score: '',
    previous_score: '',
    change_yoy: '',
  };

  const handleChange = (event) => {
    setSelectedSectors(event.target.value);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true);

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code =
      message && message.response && message.response.status && message.response.status ? message.response.status : 500;

    if (displayMessage === 'undefined') {
      toast.error('Something went wrong', TOAST_OPTIONS);
    } else if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const loadSectorList = async () => {
    AxiosInstance.get('user/sector')
      .then(function (response) {
        const finalRes = response.data.data;
        setSectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const loadCountryList = async () => {
    AxiosInstance.get('user/country')
      .then(function (response) {
        const finalRes = response.data.data;
        setCountryList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message.data.message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleDelete = (e, value) => {
    setSelectedCountry((current) => _without(current, value));
  };

  const handleSectorDelete = (e, value) => {
    setSelectedSectors((current) => _without(current, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const getSectorResult = _(sectorsList).keyBy('name').at(selectedSectors).value();

    const getFileteredSectorId = _.map(getSectorResult, 'id');
    const getCountryResult = _(countryList).keyBy('name').at(selectedCountry).value();

    const getFileteredCountryId = _.map(getCountryResult, 'id');
    const editUserBody = {
      first_name: userFirstName,
      last_name: userLastName,
      sector: getFileteredSectorId,
      country: getFileteredCountryId,
      user_company: userCompany,
      scorecard_notification: scoreCardNotification,
    };
    const url = `user/${editUserRecord.id}`;
    AxiosInstance.put(url, editUserBody, {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const { code } = response.data;
        const message = response;
        notificationMessage(message, code);
        setuserFirstName('');
        setUserLastName('');
        setUserCompany('');
        setSelectedCountry([]);
        setSelectedSectors([]);
        setScoreCardNotification(false);
        setopenUserPopup(false);
        loadUser();
      })
      .catch(function (error) {
        console.log('==>Errr', error);
        errorNotification(error, 500);
      });
  };

  const fomrStyle = {
    padding: '10px',
  };

  useEffect(() => {
    loadSectorList();
    loadCountryList();
    setuserFirstName(editUserRecord.first_name);
    setUserLastName(editUserRecord.last_name);
    setUserCompany(editUserRecord.user_company);
    setScoreCardNotification(editUserRecord.scorecard_notification === 1);
    // {editUserRecord.sector.map((item) => (
    //     setSelectedSectors(item)

    // ))}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form style={fomrStyle}>
      <Grid item xs={12} style={{ paddingBottom: '2%', paddingRight: '2%', paddingLeft: '2%' }}>
        {/* <label>First Name</label> */}
        <TextField
          type="text"
          label="First Name"
          value={userFirstName}
          onChange={(e) => setuserFirstName(e.target.value)}
          name="userFirstName"
          placeholder="Enter your first name"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Last Name</label> */}
        <TextField
          type="text"
          label="Last Name"
          value={userLastName}
          onChange={(e) => setUserLastName(e.target.value)}
          name="userLastName"
          placeholder="Enter your last name"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Company Name</label> */}
        <TextField
          type="text"
          label="Company Name"
          value={userCompany}
          onChange={(e) => setUserCompany(e.target.value)}
          name="companyName"
          placeholder="Enter your company name"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        {/* <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">Select Sectors</InputLabel>
                    <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={selectedSectors}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {sectorsList.map((item) => (
                            <MenuItem key={item.id} value={item.name} style={getStyles(item, sectorName, theme)}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-chip-label">Select Sectors</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={selectedSectors}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    clickable
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                    onDelete={(e) => handleSectorDelete(e, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {sectorsList.map((item, index) => (
              <MenuItem key={`${item.id}-${index}`} value={item.name} style={getStyles(item, sectorName, theme)}>
                <Checkbox checked={selectedSectors.indexOf(item.name) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-chip-label">Select Country</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={selectedCountry}
            onChange={handleCountryChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    clickable
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                    // onDelete={handleDelete(value)}
                    onDelete={(e) => handleDelete(e, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {countryList.map((item, index) => (
              <MenuItem key={`${item.id}-${index}`} value={item.name} style={getStyles(item, sectorName, theme)}>
                <Checkbox checked={selectedCountry.indexOf(item.name) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={scoreCardNotification}
                  onChange={() => setScoreCardNotification(!scoreCardNotification)}
                />
              }
              label="Receive Iron Blue scorecard spreadsheet with report publications"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Box style={{ paddingTop: '2%' }}>
        <div className="d-grid">
          <button
            type="submit"
            className="btn btn-dark btn-lg btn-block btn-primary_submit shadow-none"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </Box>
    </form>
  );
}
