import { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Close as CloseIcon } from '@mui/icons-material';
import { Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';

import Loader from '../BackdropLoader';
import CompanyPositionChanges from '../CompanyPositionChanges';
import SecondaryButton from './SecondaryButton';
import GridColumn from './GridColumn';
import PrimaryButton from './PrimaryButton';
import AxiosInstance from '../../../api/axios';
import StepTitle from './StepTitle';
import CloseButton from './CloseButton';

const RecalculateQueuedReportsStep = (props) => {
  const {
    title,
    reports,
    fullScreen,
    handleClose,
    maxWidth,
    handlePrev,
    handleNext,
    setOpen,
    open,
    companyId,
    companyNewScore,
  } = props;

  const [reportsLocal, setReportsLocal] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await AxiosInstance.post('admin/reports/bulkRecalculate', {
          dry_run: true,
          reports: reports.map(({ id }) => ({ id })),
          updated_company: {
            id: companyId,
            score: companyNewScore,
          },
        });
        setReportsLocal(data.data);
        if (!data.data.length) {
          setMessage('No queued reports, please recalculate the score changes');
        }
      } catch (error) {
        console.error(error);
        setMessage('No queued reports, please recalculate the score changes');
      }
    };
    fetchData();
  }, [reports, companyId, companyNewScore]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            // maxWidth: '640px',
            maxHeight: '750px',
          },
        },
      }}
    >
      <Box>
        <DialogTitle id="responsive-dialog-title">
          <StepTitle text={title} />
          <CloseButton handleClose={handleClose} />
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        dividers
      >
        {message && (
          <Typography
            style={{
              padding: '40px',
              textAlign: 'center',
            }}
          >
            {message}
          </Typography>
        )}
        {reportsLocal.map((report, index) => {
          const reportTime = moment(`${report.date_change.split('T')[0]} ${report.time}`);
          return (
            <>
              <Grid container>
                <GridColumn width="6">
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      A report on company {report.company.name} with a score change from {report.company.latest_score}{' '}
                      to {report.latest_score} is already submitted for publication on{' '}
                      {reportTime.format('YYYY-MM-DD HH:mm')} GMT, which will make the following changes:
                    </span>
                  </Box>
                </GridColumn>
              </Grid>
              <Grid container>
                <GridColumn width="6" showDivider="true">
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>Original version:</span>
                    <CompanyPositionChanges
                      topDecile={report.previous_position_changes?.topDecile || 'No changes'}
                      topQuartile={report.previous_position_changes?.topQuartile || 'No changes'}
                      bottomQuartile={report.previous_position_changes?.bottomQuartile || 'No changes'}
                      bottomDecile={report.previous_position_changes?.bottomDecile || 'No changes'}
                      showDivider={false}
                    />
                  </Box>
                </GridColumn>
                <GridColumn width="6">
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>Recalculated version:</span>
                    <CompanyPositionChanges
                      topDecile={report.position_changes?.topDecile || 'No changes'}
                      topQuartile={report.position_changes?.topQuartile || 'No changes'}
                      bottomQuartile={report.position_changes?.bottomQuartile || 'No changes'}
                      bottomDecile={report.position_changes?.bottomDecile || 'No changes'}
                      showDivider={false}
                    />
                  </Box>
                </GridColumn>
              </Grid>
              {index < reports.length - 1 && <Divider style={{ borderColor: '#8c8b8b' }} />}
            </>
          );
        })}
      </DialogContent>
      <DialogActions>
        <>
          <SecondaryButton text="Back" onClick={handlePrev} />
          <PrimaryButton text="Submit" onClick={handleNext} />
        </>
      </DialogActions>
      <Loader setOpen={setOpen} open={open} />
    </Dialog>
  );
};

export default RecalculateQueuedReportsStep;
