import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import UserHeader from '../UserHeader';
import ScoreCardContent from './ScoreCardContent';

const useStyles = makeStyles((theme) => ({
  pageWrapper: { display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' },
}));

const ResearchUserView = () => {
  const classes = useStyles();

  return (
    <Box className={classes.pageWrapper}>
      <Helmet>
        <title>Scorecard - Iron Blue Financials</title>
      </Helmet>
      <UserHeader />
      <ScoreCardContent />
    </Box>
  );
};

export default ResearchUserView;
